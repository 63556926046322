/* Initial slider settings */
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

const splide = new Splide(".splide", {
  type: "loop",
  drag: "auto",
  perPage: 1,
  autoplay: false,

  autoScroll: {
    speed: 2,
    // autoStart: true,
    pauseOnHover: true,
  },
});

splide.mount({ AutoScroll });

const { AutoScroll: autoscroll } = splide.Components;

splide.on("click", (e) => {
  if (!autoscroll.isPaused()) {
    autoscroll.pause();
    splide.go(e.index);

    cancelTimerToPlay();
    startTimerToPlay();
    return;
  }

  cancelTimerToPlay();
  autoscroll.play();
});

splide.on("drag", () => {
  autoscroll.pause();
  cancelTimerToPlay();
  startTimerToPlay();
});

let timer;

function timerToPlay() {
  autoscroll.play();
}

function startTimerToPlay() {
  timer = setTimeout(timerToPlay, 30000);
}

function cancelTimerToPlay() {
  clearTimeout(timer);
}
